<template>
  <div>
    <base-header class="pb-6">
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="2"/>
        <b-col lg="10">
          <div class="card-wrapper">
          <!--   <card v-if="flow==1">
                <b-row>
                  <b-col cols="12" class="text-center">
                    <h3 class="mb20x">ESCANEAR VENDING</h3>
                    <hr>
                  </b-col>   
                </b-row>   
                <b-row>
                  <b-col cols="12" class="text-center">
                    <b>Para poder acceder al mantenimiento de los vendings debes escanear el código QR que te genera la aplicación</b>
                  </b-col>
                </b-row>  
                <b-row>
                  <b-col cols="12" class="text-center">
                    <button class="text-sm font-weight-bold btn btn-scanning" @click="flow=2">
                      <img src="../../../public/ico-qr-code-scan-24.svg" class="button-icon" alt="">
                      <span class="button-text"><b>ESCANEAR VENDING</b></span>
                    </button>
                  </b-col>  
                </b-row>      
            </card> -->
            <card v-if="flow==1">
                <b-row>
                  <b-col cols="12" class="text-center">
                    <h3 class="mb20x">MANTENIMIENTO VENDING</h3>
                    <hr>
                  </b-col>   
                </b-row>   
                <b-row>
                  <b-col cols="12" class="text-center">
                    <b>Asegurese de que el vending no esta siendo utilizado.</b>
                  </b-col>
                </b-row> 
                 
                <b-row>
                  <b-col cols="12" class="text-center">
                    <button class="text-sm font-weight-bold btn btn-scanning" @click="initScan">
                      <span class="button-text"><b>INICIAR MANTENIMIENTO</b></span>
                    </button>
                  </b-col>  
                </b-row>  
            </card>
            <card v-if="flow==3">
                <b-row>
                  <b-col cols="12" class="text-center">
                    <h3 class="mb20x">INDIQUE EL VENDING</h3>
                    <hr>
                  </b-col>   
                </b-row>   
                <b-row>
                
                </b-row>
                <b-row>
                  <b-col md="12" class="text-center">
                      <base-input label="Centro Deportivo">
                        <el-select 
                          v-model="vendingId"
                          filterable
                          placeholder="Seleccione..."
                          style="max-width: 500px;"
                        >
                          <el-option 
                          v-for="vending in vendings"
                          :key="vending.id"
                          :label="vending.sportCenterDto.sportCenterName	+ ' || ' +  vending.serialNumber"
                          :value="vending.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                  </b-col>
                </b-row>
                <b-row>  
                  <b-col md="12" class="mb-4 text-center">
                    <base-button @click.prevent="showVending" type="primary" native-type="submit" class="btnFilter">REVISAR</base-button>
                  </b-col>
                </b-row>
            </card>
            <card v-if="flow==4">
                <b-row>
                  <b-col cols="2" class="text-center"></b-col>
                  <b-col cols="8" class="text-center">
                    <h3 class="mb20x">VENDING DEPORTIVO (INTERIOR)</h3>
                    <h3 class="mb20x">{{ getSportCenterNameById() }}</h3>
                  </b-col>
                  <b-col cols="2" class="text-center" style="display: flex; align-items: center;">
                    <b-button size="sm" @click="saveVendingConfig(null, 1)" variant="secondary" title="Configurar"><i class="ni ni-settings" style="font-size: 2.5em;"></i></b-button>
                    <b-button size="sm" @click="openDoors()" variant="secondary" title="Apertura de puertas">
                    <img src="../../../public/emergency_exit.png" height="35"/></b-button>
                  </b-col>   
                </b-row> 
                <hr> 
                <b-row>
                  <b-col cols="12" class="text-center" v-if="editedItems.length > 0">
                    <button class="text-sm font-weight-bold btn btn-close" @click="saveVending">
                      <span class="button-text"><b>CERRAR PUERTAS</b></span>
                    </button>
                    <button class="text-sm font-weight-bold btn btn-cancel-close" @click="cancelVending">
                      <span class="button-text"><b>CANCELAR CIERRE</b></span>
                    </button>
                  </b-col>  
                </b-row>   
                <b-row>
                  <b-col cols="12" class="text-center">
                    <b>Estado y edición de puertas</b><br><br>
                  </b-col>   
                </b-row> 
                <card style="background-color: #f2f2f2">
                  <b-row>
                    <b-col cols="12" md="2" class="text-left"> 
                      <h4>PUERTAS</h4> 
                    </b-col>
                    <b-col cols="12" md="2" class="text-left">
                      <h4>ESTADO</h4>  
                    </b-col> 
                    <b-col cols="12" md="3" class="text-left">  
                      <h4>MARCA</h4>
                    </b-col> 
                    <b-col cols="12" md="2" class="text-left">  
                      <h4>PRESURIZADA</h4>
                    </b-col> 
                    <b-col cols="12" md="3" class="text-right"> 
                      <h4>ACCIÓN</h4> 
                    </b-col>    
                  </b-row>
                </card>
                <card  v-for="(item, index) in vendingDoors" :key="index" :class="{'editing':item.editable || item.saved, 'text-white':!item.editable || item.saved}">
                  <b-row>
                    <b-col cols="12" md="2" class="text-left"> 
                      <h4 :class="{'text-white':item.editable || item.saved}">{{ item.doorNumber }}</h4> 
                    </b-col>
                    <b-col cols="12" md="2" class="text-left">
                      <h4 :class="{'text-white':item.editable || item.saved}">
                        <span :style="{backgroundColor: item.tpStatusDto.color}" class="circle" v-if="!item.editable"></span>
                        <span v-if="!item.editable">{{ getNombreEstado(item.tpStatusDto.id) }}</span> 
                        <base-input v-if="item.editable">
                          <el-select 
                            v-model="item.tpStatusDto.id"
                            filterable
                            placeholder=""
                          >
                            <el-option 
                            v-for="status in statusList"
                            :key="status.id"
                            :label="status.name"
                            :value="status.id"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                      </h4>  
                    </b-col> 
                    <b-col cols="12" md="3" class="text-left">  
                      <h4 :class="{'text-white':item.editable || item.saved}">
                        <img
                            v-if="!item.editable"
                            :src="`/logos/${item.tubeDto.id}.svg`"
                            width="20"
                            class="mr-2"
                          /><span v-if="!item.editable">{{ getNombreMarca(item.tubeDto.id) }}</span> 
                          <base-input v-if="item.editable">
                            <el-select 
                              v-model="item.tubeDto.id"
                              filterable
                              placeholder=""
                            >
                              <el-option 
                              v-for="tube in tubeList"
                              :key="tube.id"
                              :label="tube.name"
                              :value="tube.id"
                              >
                              <img
                                v-if="!item.editable"
                                :src="`/logos/${tube.id}.svg`"
                                width="20"
                                class="mr-2"
                              />
                              {{ tube.name }}
                              </el-option>
                            </el-select>
                          </base-input>  
                      </h4>
                    </b-col> 
                    <b-col cols="12" md="2" class="text-left">  
                      <h4 :class="{'text-white':item.editable || item.saved}">
                          <span>{{ getCheckPressurizedName(item.checkPressurized) }}</span> 
                          <!-- <base-input v-if="item.editable">
                            <el-select 
                              v-model="item.checkPressurized"
                              filterable
                              placeholder=""
                            >
                              <el-option 
                              v-for="pre in checkPressurizedList"
                              :key="pre.value"
                              :label="pre.label"
                              :value="pre.value"
                              >
                              {{ pre.label }}
                              </el-option>
                            </el-select>
                          </base-input> -->
                      </h4>
                    </b-col> 
                    <b-col cols="12" md="3" class="text-right"> 
                      <b-button size="sm" @click="editRow(item)" v-if="!item.editable" variant="secondary"><img
                        :src="`ico-pencil-64.svg`"
                        width="13"
                        height="13"
                        title="Editar"
                      /></b-button>
                      <div class="btn-group" v-if="!item.editable">
                        <b-dropdown size="sm" variant="secondary" right>
                          <template #button-content>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                            </svg>
                          </template>
                          <b-dropdown-item @click="saveVendingConfig(item, 2)">Abrir puertas</b-dropdown-item>
                          <b-dropdown-item @click="saveVendingConfig(item, 3)">Bloquear puertas</b-dropdown-item>
                        </b-dropdown>  
                      </div>
                      <b-button size="sm" @click="saveRow(item)"  v-if="item.editable" variant="success" title="Guardar"><i class="ni ni-check-bold"></i></b-button>
                      <b-button size="sm" @click="cancelEditRow(item)" v-if="item.editable" variant="danger" title="Cancelar"><b>X</b></b-button> 
                    </b-col>    
                  </b-row> 
                </card>
               <!--  <b-row>
                  <b-col cols="12" class="text-center">
                    <button class="text-sm font-weight-bold btn btn-scanning" @click="flow=2">
                      <img src="../../../public/ico-qr-code-scan-24.svg" class="button-icon" alt="">
                      <span class="button-text"><b>ESCANEAR VENDING</b></span>
                    </button>
                  </b-col>  
                </b-row>   --> 
            </card>
            <card v-if="flow==5">
                <b-row>
                  <b-col cols="12" class="text-center">
                    <h3 class="mb20x">ESCANEAR CODIGO QR</h3>
                    <hr>
                  </b-col>   
                </b-row>
                <b-row>
                  <b-col cols="12" class="text-center">
                    <vue-qr :bgSrc='src' :logoSrc="src2" :text=qrcode :size="300"></vue-qr>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="3" class="text-left"> 
                      <b-button size="sm" @click="flow=4" variant="secondary" title="Regresar"><i class="ni ni-bold-left" style="font-size: 2.5em;"></i></b-button>
                  </b-col>
                </b-row>    
                <!-- <b-row>
                  <b-col cols="12" class="text-center"> 
                      <img src="../../../public/ico-error.svg" alt="">
                      <span class="button-text error-message"><b>ERROR DE ACCESO</b></span>
                    </b-col>   
                </b-row> 
                <b-row>
                  <b-col cols="12" class="text-center">    
                    <span class="button-text"><br><br><b>Se ha producido un error al escanear el código QR.</b></span><br>
                    <span class="button-text"><b>Vuelva a escanear el código.</b></span>
                  </b-col>   
                </b-row> 
                 <b-row>
                  <b-col cols="12" class="text-center">
                    <button class="text-sm font-weight-bold btn btn-scanning" @click="flow=2">
                      <img src="../../../public/ico-qr-code-scan-24.svg" class="button-icon" alt="">
                      <span class="button-text"><b>ESCANEAR VENDING</b></span>
                    </button>
                  </b-col>  
                </b-row>    -->
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import api from '@/api/services'
  import { Select, Option } from 'element-ui'
  import { mapActions, mapGetters } from 'vuex'
  import VueQr from 'vue-qr'

  export default {
    name: 'form-components',
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      VueQr
    },
    data() {
      return {
        fields: [
          { key: 'doorNumber', label: 'Puertas' },
          { key: 'tpStatusDto', label: 'Estado' },
          { key: 'tubeDto', label: 'Marca' },
          { key: "actions", label: "Acción" },
        ],
         flow: 1,
         vendingId: null,
         vendings: [],
         vendingDoors: [],
         tubeList: [],
				 statusList: [],
         editedItems: [],
         checkPressurizedList: [
          { label: 'Sí', value: true },
          { label: 'No', value: false }
        ],
         qrcode: ''
      }
    },

    computed: {
      ...mapGetters({
        appName: 'appName',
        authUser: 'authUser'
      })
    },

    async mounted () {
      this.flow = 1;
    },

    methods: {
      ...mapActions({
        setAppName: 'setAppName'
      }),

      editRow(item) {
        this.$set(item, 'editable', true);
        this.createMaintenance(item.id);
      },

      saveRow(item) {
        this.$set(item, 'saved', true);
        this.$set(item, 'editable', false);
        const editItem = {
          id: item.id,
          checkPressurized: item.checkPressurized,
          doorNumber: item.doorNumber,
          tpStatusDto: {
            id: item.tpStatusDto.id,
            name: this.getNombreEstado(item.tpStatusDto.id),
            color: this.getColorEstado(item.tpStatusDto.id)
          },
          tubeDto: {
            id: item.tubeDto.id,
            name: this.getNombreMarca(item.tubeDto.id),
          }
        }
        this.updateEditedItems(editItem);
        this.updateMaintenance(item);
      },

      updateEditedItems(editItem) {
        
        const existingIndex = this.editedItems.findIndex(item => item.id === editItem.id);

        if (existingIndex >= 0) {
          this.editedItems[existingIndex] = editItem;
        } else {
          this.editedItems.push(editItem);
        }
      },

      async createMaintenance(doorId) {
        const data = {
          doorId: doorId
        }
        await api.setMaintenance(data, this.authUser);
      },

      async updateMaintenance(item) {
        const data = {
          doorId: item.id,
          tpTubeId: item.tubeDto.id,
          tpStatusId: item.tpStatusDto.id
        }
        await api.updateMaintenance(data, this.authUser);
      },

      async saveVending() 
			{
				await api.updateVending(this.authUser, this.vendingId, this.editedItems);
				this.editedItems = [];
        this.showVending();
			},   

      async openDoors() 
			{
        let item = {
            vendingId: this.vendingId,
            email: null,
            operation: "open",
            doorId: 0
        }
        let resp = await api.setVendingConfig(this.authUser, item);
        if (typeof(resp) == 'object' || Array.isArray(resp) == true) {  
          this.qrcode = resp.data
          this.flow = 5;
				}
      },

      async saveVendingConfig(row, type) 
			{
        let item = {
            vendingId: this.vendingId,
            email: null,
            operation: row? "open":"config",
            doorId: row? row.doorNumber : null,
            id: row? row.id : null
        }
        if (type === 1) { //Anular puerta
          item.doorId = null
        }
        if (type === 2) { //Abrir puerta
          item.operation = "open"
        }
        if (type === 3) { //Cerrar puerta
          item.operation = "close"
        }
				let resp = await api.setVendingConfig(this.authUser, item);
		    if (typeof(resp) == 'object' || Array.isArray(resp) == true) {  
          this.qrcode = resp.data
          this.flow = 5;
				} 
				
			},   

      cancelVending() {
        this.editedItems = [];
        this.showVending();
      },

      cancelEditRow(item) {
        this.$set(item, 'editable', false);
      },

      async initScan() {
        this.flow =3;
        this.getVendings();
        this.getResources();
      },

      async getResources() {
				//Llammar apis
				let resp = await api.getEstado(this.authUser)
				if (typeof(resp.message) == 'object' || Array.isArray(resp) == true) {  
					this.statusList = resp
				} 

				resp = await api.getMarca(this.authUser) 
				if (typeof(resp.message) == 'object' || Array.isArray(resp) == true) {  
					this.tubeList = resp
				} 
			},

      getCheckPressurizedName(value) {
        const result = this.checkPressurizedList.find(item => item.value === value);
        return result ? result.label : '';
      }, 

      getNombreEstado(id) {
				const status = this.statusList.find(item => item.id === id);
				return status ? status.name : '';
			},

      getColorEstado(id) {
				const status = this.statusList.find(item => item.id === id);
				return status ? status.color : '';
			},

      getNombreMarca(id) {
				const marca = this.tubeList.find(item => item.id === id);
				return marca ? marca.name : '';
			},

      async getVendings()
      {
        const resp = await api.getVendings(this.authUser);
        this.vendings = [];
        if (typeof(resp.message) == 'object' || Array.isArray(resp) == true) {  
          this.vendings = resp
        }  
      },

      async showVending()
      {
        this.flow = 4;
        let resp = await api.showVending(this.authUser, this.vendingId);
        //console.log(resp);
        this.vendingDoors = [];
        //resp = '';
        if (typeof(resp.message) == 'object' || Array.isArray(resp) == true) {  
          this.vendingDoors = resp
          return
        } 
        this.vendingId = null;
        this.flow = 5; 
      },
      
      getSportCenterNameById() {
        const vending = this.vendings.find(v => v.id === this.vendingId);
        return vending ? vending.sportCenterDto.sportCenterName.toUpperCase() : '';
      },
    }
  }
</script>
<style>
th{
  font-weight: bold !important;
  color:#000 !important;
  font-size:0.8rem !important;
  font-weight: bold;
  /* background-color:#11d5e3 !important; */
}
td {
    font-size: 1rem !important;
}

thead{
  box-shadow: 2px 0px 25px 4px rgb(105 95 151 / 6%) !important;
}

.table-striped>tbody>tr:nth-child(odd)>td, 
      .table-striped>tbody>tr:nth-child(odd)>th {
       /* background-color: #f5f5f5; */
      }
      .table-striped>tbody>tr:nth-child(even)>td, 
      .table-striped>tbody>tr:nth-child(even)>th {
       /* background-color: #f5f5f5; */
      }
      .table-striped>thead>tr>th {
         /* background-color: #f6f9fc; */
      }

.mb20x{
    margin-bottom:20px;
}
.flexCenter {
  display: flex;
  align-items: center;
}

.btnBusqueda{
  background:#e5fcfd;
  border:none;
  color:#7096ab;
  padding: 5px;
  font-size: 12px;
  margin-bottom:20px;
}

.mxW13x{
  max-width: 13px;
}

.mxW25x{
  max-width: 25px;
}

.W20x{
  width:20px;
}

.dropdown-toggle {
  background: #e5fcfd;
  padding: 5px;
}

.w200X{
  min-width: 200px;
}

.p5x{
  padding:5px;
}

.inlineBlock {
		display: inline-block;
}

.iconVending::before {
		background-size: 15px;
		background-position-y: 7px;
}

.mxW15x{
  max-width: 15px;
}

.btnFilter{
  background: #003d52;
  border:none;
  color: #fff;
  padding: 5px;
  font-size:  87.5%;
  margin-bottom:20px;
  height: 40px;
  width: 150px
}

.btn-scanning{
  background-color: #e0523d !important;
  color: white !important;
  margin-bottom:40px;
  margin-top: 100px;
}

.btn-close{
  background-color: #e0523d !important;
  color: white !important;
  margin-bottom:20px;
  margin-top: 20px;
}

.btn-cancel-close{
  border-color: #e0523d !important;
  margin-bottom:20px;
  margin-top: 20px;
}

.button-text {
  margin-left: 8px; /* Ajusta el valor según el espacio deseado */
}

.button-icon {
  max-width: 20px;
  filter: invert(100%);
}

.img-qr{
  margin-top: 70px;
}

.error-message {
  border:none;
  color:#e0523d !important;
  padding: 5px;
  font-size: 14px;
}

.circle {
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		margin-right: 5px;
	}

.editing {
  background-color:  #003d52;
}
</style>
